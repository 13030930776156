<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <v-card class="mx-auto pa-3 mb-3" max-width="800" outlined>
      <div class="d-flex justify-space-between align-center">
        <div class="text-h6">
          <v-avatar v-if="institution && institution.name" class="mr-3" color="black" size="30">
            <v-img v-if="institution.logoUrl" :src="institution.logoUrl"></v-img>
            <span v-else class="white--text text-h6">{{institution.name.charAt(0).toUpperCase()}}</span>
          </v-avatar>
          {{ (institution.name)? institution.name : '• • •' }}</div>
      </div>
    </v-card>

    <ShareLink type="institution" :id="this.$route.params.id"></ShareLink>
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>School Dashboard</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <v-list>
            <v-list-item
              v-for="(item, i) in menus"
              :key="i"
              class="menu-item"
              @click="clickMenu(item)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="text-left"
                  v-text="item.text"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon v-if="item.count !== null">{{ item.count }}</v-list-item-icon>
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon></v-list-item-icon
              >
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import ShareLink from "@/components/Dashboard/ShareLink.vue";
export default {
  components: {
    ShareLink,
  },
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    gameUrl: null,
    totalPage: 0,
    search: "",

    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    menus: [
      {
        text: "Events",
        icon: "mdi-calendar",
        route: "PageEducatorInstitutionEvent",
        count: null,
      },
      {
        text: "Classrooms",
        icon: "mdi-google-classroom",
        route: "PageEducatorInstitutionClassroom",
        count: 0,
      },
      // { text: "Homeworks", icon: "mdi-bag-personal", route: null, count: 0 },
      // {
      //   text: "Students",
      //   icon: "mdi-account-group",
      //   route: "PageEducatorInstitutionStudent",
      //   count: 0,
      // },
      { 
        text: "Teachers", 
        icon: "mdi-human-male-board", 
        route: 'PageEducatorInstitutionTeacher', 
        count: 0 
      },
      // { 
      //   text: "Sponsorship", 
      //   icon: "mdi-crown", 
      //   route: 'PageEducatorInstitutionSponsorship', 
      //   count: 0 
      // },
      { 
        text: "Hamochi Care", 
        icon: "mdi-heart", 
        route: 'PageEducatorInstitutionSponsoredStudent', 
        count: 0 
      },
      {
        text: "Institution Info",
        icon: "mdi-information",
        route: "PageEducatorInstitutionRead",
        count: null,
      },
    ],
    headers: [
      { text: "Date of application", value: "applicationDate" },
      {
        text: "Type",
        value: "type",
      },
      { text: "Name", value: "name" },
      { text: "Mobile Number", value: "mobile" },
      { text: "Email Address", value: "email" },
    ],
    institution: [],
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs = [
      {
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      },
      {
        text: "My Institution",
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.id },
        },
        exact: true,
      }
    ]
    //EOC
    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.institution = resp;
      //this.updateBreadcrumb(resp);
      this.menus[this.$_.findIndex(this.menus,{text:"Classrooms"})].count = this.institution.classroomCount
      // this.menus[2].count = this.institution.studentCount
      this.menus[this.$_.findIndex(this.menus,{text:"Teachers"})].count = this.institution.teacherCount 
      this.menus[this.$_.findIndex(this.menus,{text:"Hamochi Care"})].count = this.institution.sponsorshipCount 
    };
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    // updateBreadcrumb(resp) {
    //   this.breadcrumbs[1].text = resp.name
    // },
    handleClick(item) {
      this.$router.push({
        name: "PageEducatorInstitutionClassroomStudent",
        params: { parentId: this.$route.params.id, id: item.id },
      });
      console.log(item);
    },
    clickMenu(item) {
      this.$router.push({
        name: item.route,
        params: { parentId: this.$route.params.id },
      });
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
.form-content-pending {
  width: 300px !important;
}
</style>